import Default from '../../../common/Themes/Default/Theme';
import { sectionHeadingAlignment, sectionHeadingHR } from '../../../common/utils/themeOverrides';
import * as modernIcons from '../../../common/IconPacks/modernThinRound';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Balance } from '../../../common/loaders';
import { levelFilter } from '../../../common/utils/text';
import { sectionHrTypes, WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../../common/constants/headerTreatments';
import themeConfig from '../themeConfig';

const { INLINE } = sectionHrTypes;
const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highlight',
    fontSize: 'xlarge',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

class Theme15 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme15';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        hasLogoAlign: true,
        useSocialLinks: true,
        phoneOnSecondaryPage: true,
        headerTreatmentsConfig: {
          ...defaultProps.headerTreatmentsConfig,
          heroContentItems: ['tagline', 'tagline2', 'cta', 'phone'],
          fillConfig: {
            props: {
              fixedMinHeight: '500px',
              useParallax: false,
              useContentParallax: false
            }
          },
          imageTreatments: {
            [FILL]: 'none',
            [FIT]: 'none',
            [INSET]: 'category-alt-solid',
            [BLUR]: 'none',
            [LEGACY_BLUR]: 'none'
          }
        }
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(widgetType === 'HEADER' && { logoAlign: 'center' })
    };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      spacingSmall: '12px',
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          style: {
            font: 'primary',
            color: 'highlight',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none',
            ['@sm']: {
              fontSize: 'xxxlarge'
            }
          }
        },
        HeadingBeta: {
          ...typographyShared1
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highlight',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'uppercase'
          }
        },
        ButtonAlpha: {
          style: {
            font: 'alternate',
            fontWeight: 'bold',
            letterSpacing: 'normal',
            textTransform: 'uppercase',
            ['@xs-only']: {
              fontSize: 'xsmall'
            }
          }
        }
      }
    };
  }

  Heading(props) {
    const { level, tag } = props;
    const typography = {
      5: 'DetailsAlpha'
    }[levelFilter({ tag, level })];
    return super.Heading(this.merge({ typography }, props));
  }

  NavListItemInline(props) {
    return super.ListItemInline(
      this.merge(props, {
        style: {
          paddingLeft: 'xlarge'
        }
      })
    );
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...modernIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Balance.apply(this, [props]);
  }

  SectionHeading({ layout = 'full', ...props }) {
    const { base } = this;
    const overrides = this.merge(
      {
        style: {
          overflow: 'hidden',
          textAlign: 'center',
          ['@md']: {
            textAlign: layout === 'full' ? 'center' : 'left'
          }
        },
        sectionHeadingHR: INLINE
      },
      sectionHeadingAlignment(base),
      sectionHeadingHR(base)
    );
    return super.SectionHeading(this.merge(overrides, props));
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            marginRight: 'medium',
            height: '0.8em',
            backgroundColor: 'sectionLowContrast'
          }
        },
        props
      )
    );
  }

  UtilitiesMenu(props) {
    return super.UtilitiesMenu(
      this.merge(
        {
          style: {
            '@md': {
              marginLeft: 'medium'
            }
          }
        },
        props
      )
    );
  }

  Input(props) {
    return super.Input(
      this.merge(
        {
          style: {
            borderColor: 'input',
            borderRadius: 'medium',
            borderWidth: 'xsmall',
            paddingVertical: 'xsmall',
            paddingHorizontal: 'xsmall',
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(
        {
          style: {
            left: '11px',
            top: '35%'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          style: {
            borderColor: 'input',
            borderRadius: 'medium',
            borderWidth: 'xsmall',
            borderStyle: 'solid',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingVertical: 'xsmall',
            paddingHorizontal: 'xsmall',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  MediaObject(props) {
    return super.MediaObject(
      this.merge(
        {
          style: {
            alignItems: 'center'
          }
        },
        props
      )
    );
  }

  BlogAside(props) {
    return super.BlogAside(
      this.merge(
        {
          section: 'alt',
          style: {
            height: 'auto',
            backgroundColor: 'section',
            paddingVertical: 'large',
            paddingHorizontal: 'large',
            borderTopWidth: '0',
            ['@md']: {
              paddingVertical: 'large',
              borderWidth: '0'
            }
          }
        },
        props
      )
    );
  }

  DisplayHeading(props) {
    return super.DisplayHeading(this.merge({ typography: 'HeadingBeta' }, props));
  }

  HeroText(props) {
    return this.SubTagline(props);
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          style: {
            marginBottom: 'xsmall'
          }
        },
        props
      )
    );
  }

  SubTagline(props) {
    return super.SubTagline(
      this.merge(
        {
          typography: 'HeadingDelta',
          style: {
            lineHeight: 'level3'
          }
        },
        props
      )
    );
  }

  NavFooterLink(props) {
    return super.NavFooterLink(this.merge({ typography: 'LinkAlpha' }, props));
  }

  FooterLink(props) {
    return super.FooterLink(this.merge({ typography: 'NavAlpha' }, props));
  }

  Phone(props) {
    return super.Phone(this.merge({ typography: 'BodyBeta', featured: false }, props));
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  HeadingMajor(props) {
    return super.HeadingMajor(this.merge({ typography: 'HeadingAlpha' }, props));
  }

  CardBannerHeading(props) {
    return super.CardBannerHeading(this.merge({ typography: 'HeadingAlpha' }, props));
  }

  ContentCard(props) {
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(styles, props));
  }
}

export default Theme15;
